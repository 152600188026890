<template>
    <footer class="sim-footer">
        <CoreContainer :aria-label="$t('accessibility.footer')">
            <BaseContainerContent class="sim-footer__main-content" :padding="{ top: 'large' }">
                <div class="sim-footer__logo-area">
                    <!--  Company Logo  -->
                    <NuxtLink :to="localePath('/')">
                        <span class="visually-hidden">
                            {{ $t('accessibility.logo_link') }}
                        </span>
                        <LogoComponent light />
                    </NuxtLink>

                    <!--  Company Description  -->
                    <p class="sim-footer__text">
                        {{ $t('footer.text') }}
                    </p>

                    <NuxtLink :to="$t('links.simploshop')"
                              target="_blank"
                              external
                              class="sim-footer__more-about"
                    >
                        {{ $t('footer.more_about') }}

                        <IconArrowRight />
                    </NuxtLink>
                </div>

                <div class="sim-footer__cols">
                    <!--  CATEGORIES  -->
                    <FooterMenu
                        :menu="transformedMenuData"
                    />

                    <!--  ROOMS  -->
                    <div>
                        <FooterMenu :menu="transformedRoomsData">
                            <NuxtLink v-if="roomsCategoryResponse"
                                      :to="roomsCategoryResponse.getItem()?.urls ?? undefined"
                                      class="sim-footer__more-items"
                            >
                                {{ $t('footer.all_rooms') }}
                            </NuxtLink>
                        </FooterMenu>
                    </div>

                    <!--  CONTACT US  -->
                    <div>
                        <span class="sim-h5 sim-footer__title">
                            {{ $t('footer.contact_us') }}
                        </span>

                        <div class="flex items-center gap-3">
                            <IconFooterPhone class="text-primary-400" width="1.75rem" height="1.75rem" />

                            <div class="flex flex-col">
                                <span class="sim-footer__contact-title">
                                    {{ $t('labels.phone') }}
                                </span>

                                <a :href="'tel:' + $t('global.phone').replace(/\s/g, '')" class="sim-footer__contact-link">
                                    {{ $t('global.phone') }}
                                </a>
                            </div>
                        </div>

                        <div class="mt-6 flex items-center gap-3">
                            <IconFooterMail class="text-primary-400" width="1.75rem" height="1.75rem" />

                            <div class="flex flex-col">
                                <span class="sim-footer__contact-title">
                                    {{ $t('labels.email') }}
                                </span>

                                <a :href="'mailto:' + $t('global.mail')" class="sim-footer__contact-link">
                                    {{ $t('global.mail') }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <!--  FOLLOW US  -->
                    <div>
                        <span class="sim-h5 sim-footer__title">
                            {{ $t('footer.follow_us') }}
                        </span>

                        <!--  Socials  -->
                        <LazySocialsComponent />
                    </div>
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <FooterSignature />
    </footer>
</template>

<script lang="ts" setup>
import type { NavigationLinkWithoutSubLinks } from '../navbar/NavbarComponent.vue'
import { CategoryId } from '../../assets/ts/enums/model-ids'

const { t } = useI18n()
const localePath = useLocalePath()

// TODO: merge with navbar (use response from store or sth.)
// TODO: make react to locale change
const { data: menuCategoriesResponse } = await useCategoriesTreeApiService().useGetForMenu()

const transformedMenuData = computed(() => {
    const menuItems = menuCategoriesResponse?.value?.getItems() || []

    return {
        title: t('labels.categories'),
        links: menuItems.filter(item => item.id !== CategoryId.ROOMS).map(item => ({
            label: item.name || '',
            url: item.urls || '',
        })),
    }
})


const [
    { data: roomsResponse },
    { data: roomsCategoryResponse },
] = await Promise.all([
    useCategoriesApiService()
        .whereEquals(CategoryModel.ATTR_PARENT_ID, CategoryId.ROOMS)
        .onlyAttrs([
            CategoryModel.ATTR_NAME,
        ])
        .embed([
            CategoryModel.EMBED_URLS,
            CategoryModel.EMBED_IMAGE_URL,
        ])
        .useGet({
            ssrCache: {
                key: 'footer-rooms-subcategories',
                ttl: 60 * 30,   // 30 min
                swr: true,
            },
        }),
    useCategoriesApiService()
        .onlyAttrs([
            CategoryModel.ATTR_NAME,
        ])
        .embed([
            CategoryModel.EMBED_URLS,
        ])
        .forId(CategoryId.ROOMS)
        .useGet({
            ssrCache: {
                key: 'footer-rooms-category',
                ttl: 60 * 30,   // 30 min
                swr: true,
            },
        }),
])

const transformedRoomsData = computed(() => {
    const roomsItems = roomsResponse?.value?.getItems() || []

    return {
        title: t('labels.rooms'),
        links: roomsItems.slice(0, 6).map(item => ({
            label: item.name || '',
            url: item.urls || '',
        })),
    }
})

export interface FooterNavigationList {
    title: string
    links: NavigationLinkWithoutSubLinks[]
}
</script>

<style lang="scss" scoped>
.sim-footer {
    background-color: $sim-c-primary-900;
    color: $sim-c-basic-100;
}

.sim-footer__main-content {
    @include more-than(xxl) {
        display: grid;
        gap: 15rem;
        grid-template-columns: 2fr 10fr;
    }
}

.sim-footer__logo-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
}

.sim-footer__text {
    color: $sim-c-basic-400;

    @include sim-text-small;

    line-height: 175%;
}

.sim-footer__more-about {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: $sim-c-simplo;

    transition: all 0.25s ease-out;

    @include sim-text-small;
    @include sim-font-medium;

    &:hover {
        gap: 1rem;
    }
}

.sim-footer__cols {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem 1rem;

    margin-top: 3.75rem;

    @include more-than(md) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
    }

    @include more-than(xxl) {
        display: flex;
        justify-content: space-around;

        margin-top: 0;
    }
}

.sim-footer__title {
    display: block;
    text-transform: uppercase;
    margin-bottom: 1.375rem;

    @include sim-text-normal;
    @include sim-font-bold;
}

.sim-footer__more-items {
    display: block;
    margin-top: 0.75rem;

    @include sim-text-small;
    @include sim-font-bold;

    &:hover {
        text-decoration: underline;
    }
}

.sim-footer__contact-title {
    display: block;

    @include sim-text-small;
    @include sim-font-bold;
}

.sim-footer__contact-link {
    display: block;
    white-space: nowrap;

    @include sim-text-normal;

    &:hover {
        text-decoration: underline;
    }
}

.sim-footer__signature {
    background-color: $sim-c-primary-900;
    color: $sim-c-white;
}
</style>
